<template>
  <section>
    <q-permissions
      :__PERMISSIONS__="permStatus"
      :page="'compte'"
      :index="'index'"
    />
    <div class="" v-if="permStatus">
      <!-- Modal pour ajouter un compte -->
      <b-modal
        id="modal-login"
        cancel-variant="outline-secondary"
        ok-title="Créer"
        cancel-title="Annuler"
        centered
        title="Ajouter un compte"
        @ok="handleOk"
      >
        <validation-observer ref="registerForm">
          <b-form class="auth-register-form mt-2">
            <!-- <div>
            <h5 class="text-danger text-center">{{ compte_exist }}</h5>
          </div> -->
            <!-- Numero compte -->
            <b-form-group label-for="register-numeroCompte">
              <label for=""
                >Numero de compte<span class="p-0 text-danger h6"></span
              ></label>
              <validation-provider
                #default="{ errors }"
                name="nom"
                rules="required"
              >
                <b-form-input
                  id="register-nom"
                  @input="validateNumeroCompte"
                  v-model="numeroCompte"
                  name="register-numeroCompte"
                  :state="errors.length > 0 ? false : null"
                  placeholder="AZ000000EH09"
                />
                <small
                  :class="valideNumeroCompte ? 'block' : 'none'"
                  class="text-danger"
                >
                  Vous devez renseigner le numero du compte
                </small>
              </validation-provider>
            </b-form-group>

            <!-- Libelle -->
            <b-form-group label-for="register-libelle">
              <label for=""
                >Libelle du compte<span class="p-0 text-danger h6"></span
              ></label>
              <validation-provider
                #default="{ errors }"
                name="libelle"
                rules="required"
              >
                <b-form-input
                  id="register-libelle"
                  @input="validateLibelle"
                  v-model="libelle"
                  name="register-libelle"
                  :state="errors.length > 0 ? false : null"
                  placeholder="NSIA, ECOBANK"
                />
                <small
                  :class="valideLibelle ? 'block' : 'none'"
                  class="text-danger"
                >
                  Vous devez renseigner le libelle
                </small>
              </validation-provider>
            </b-form-group>

            <!-- solde -->
            <b-form-group label-for="register-solde">
              <label for=""
                >Solde<span class="p-0 text-danger h6"></span
              ></label>
              <validation-provider
                #default="{ errors }"
                name="solde"
                rules="required"
              >
                <b-form-input
                  id="register-solde"
                  @input="validateSolde"
                  v-model="solde"
                  type="text"
                  name="register-solde"
                  :state="errors.length > 0 ? false : null"
                  placeholder="1000000000"
                />
                <small
                  :class="valideSolde ? 'block' : 'none'"
                  class="text-danger"
                >
                  Veuillez entrer votre solde
                </small>
                <small
                  :class="valeurGood ? 'block' : 'none'"
                  class="text-danger"
                >
                  Saisie incorrecte. Vous devez renseigner un nombre avec ou
                  sans virgule.<br />
                  Un POINT( . ) à la place de la virgule( , )
                </small>
              </validation-provider>
            </b-form-group>

            <!-- Description -->
            <b-form-group>
              <label for="taxeValue">Description (facultatif):</label>
              <b-form-textarea
                id="textarea"
                v-model="description"
                placeholder="Entrer les details du compte"
                rows="5"
                max-rows="6"
              >
              </b-form-textarea>
            </b-form-group>
          </b-form>
        </validation-observer>
        <template #modal-footer>
          <b-button
            :disabled="state.loading === true ? true : false"
            variant="primary"
            @click.stop.prevent="handleOk"
          >
            <span v-if="state.loading === false">Ajouter</span>
            <b-spinner
              v-if="state.loading === true"
              label="Spinning"
            ></b-spinner>
          </b-button>
        </template>
      </b-modal>

      <!-- Modal pour mettre à jour un compte   -->
      <b-modal
        id="modal-update"
        cancel-variant="outline-secondary"
        ok-title="Modifier"
        cancel-title="Annuler"
        centered
        title="Modifier un compte"
        @ok="updateCompte"
      >
        <b-form class="auth-register-form mt-2">
          <!-- nom -->
          <b-form-group label-for="register-numero_compte">
            <label for=""
              >Numero du compte<span class="p-0 text-danger h6"></span
            ></label>
            <validation-provider
              #default="{ errors }"
              name="nom"
              rules="required"
            >
              <b-form-input
                id="register-numero"
                @input="validateEditNumeroCompte"
                v-model="editNumeroCompte"
                name="register-numero_compte"
                :state="errors.length > 0 ? false : null"
                placeholder="AZE12000067GF"
              />
              <small
                :class="valideEditNumeroCompte ? 'block' : 'none'"
                class="text-danger"
              >
                Vous devez renseigner le numero du compte
              </small>
            </validation-provider>
          </b-form-group>

          <!-- libelle -->
          <b-form-group label-for="register-libelle">
            <label for=""
              >Libelle du compte<span class="p-0 text-danger h6"></span
            ></label>
            <validation-provider
              #default="{ errors }"
              name="libelle"
              rules="required"
            >
              <b-form-input
                id="register-libelle"
                @input="validateEditLibelle"
                v-model="editLibelle"
                name="register-libelle"
                :state="errors.length > 0 ? false : null"
                placeholder="NSIA, ECOBANK"
              />
              <small
                :class="valideEditLibelle ? 'block' : 'none'"
                class="text-danger"
              >
                Vous devez renseigner le libelle du compte
              </small>
            </validation-provider>
          </b-form-group>

          <!-- email -->

          <!-- solde -->
          <b-form-group label-for="register-solde">
            <label for="">Solde<span class="p-0 text-danger h6"></span></label>
            <validation-provider
              #default="{ errors }"
              name="solde"
              rules="required"
            >
              <b-form-input
                disabled
                id="register-solde"
                @input="validateEditSolde"
                v-model="editSolde"
                name="register-solde"
                :state="errors.length > 0 ? false : null"
                placeholder="1000000000"
              />
              <small
                :class="valideEditSolde ? 'block' : 'none'"
                class="text-danger"
              >
                Veuillez entrer votre solde
              </small>
              <small
                :class="valeurEditGood ? 'block' : 'none'"
                class="text-danger"
              >
                Saisie incorrecte. Vous devez renseigner un nombre avec ou sans
                virgule.<br />
                Un POINT( . ) à la place de la virgule( , )
              </small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="taxeValue">Description (Facultatif):</label>
            <b-form-textarea
              id="textarea"
              v-model="editDescription"
              placeholder="Entrer les details du compte"
              rows="5"
              max-rows="6"
            >
            </b-form-textarea>
          </b-form-group>
        </b-form>
        <template #modal-footer>
          <b-button
            :disabled="state.loading === true ? true : false"
            variant="primary"
            @click.stop.prevent="updateCompte"
          >
            <span v-if="state.loading === false">Modifer</span>
            <b-spinner
              v-if="state.loading === true"
              label="Spinning"
            ></b-spinner>
          </b-button>
        </template>
      </b-modal>

      <!-- Modal pour transferer de l'argent depuis le compte  -->
      <b-modal
        id="modal-transfert"
        cancel-variant="outline-secondary"
        ok-title="Modifier"
        cancel-title="Annuler"
        centered
        title="Modifier un compte"
        @ok="transferer"
      >
        <b-form class="auth-register-form mt-2">
          <h4 class="text-center text-info">
            Information crediteur
            <feather-icon icon="CornerLeftUpIcon" size="18" />
          </h4>

          <!-- libelle -->
          <b-form-group label-for="register-libelle">
            <label for=""
              >Libelle du compte<span class="p-0 text-danger h6"></span
            ></label>
            <validation-provider
              #default="{ errors }"
              name="libelle"
              rules="required"
            >
              <v-select
                v-model="compteDebit"
                placeholder="Selectionnez un compte"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="errors.length > 0 ? false : null"
                label="libelle"
                rules="required"
                @input="validateCompteDebit"
                :options="compteList"
              >
              </v-select>
              <small
                :class="valideCompteDebit ? 'block' : 'none'"
                class="text-danger"
              >
                Vous devez choisir un compte debiteur
              </small>
            </validation-provider>
          </b-form-group>
          <!-- montant -->
          <b-form-group label-for="register-solde">
            <label for=""
              >Montant à transferer
              <span class="p-0 text-danger h6">*</span></label
            >
            <validation-provider
              #default="{ errors }"
              name="solde"
              rules="required"
            >
              <b-form-input
                id="register-montant"
                @input="validateMontantDebit"
                v-model="montantDebit"
                type="number"
                name="register-montant"
                :state="errors.length > 0 ? false : null"
                placeholder="1000000000"
              />
              <small
                :class="valideMontantDebit ? 'block' : 'none'"
                class="text-danger"
              >
                Veuillez entrer le montant
              </small>
              <small :class="valeurGood ? 'block' : 'none'" class="text-danger">
                Saisie incorrecte. Vous devez renseigner un nombre avec ou sans
                virgule.<br />
                Un POINT( . ) à la place de la virgule( , )
              </small>
            </validation-provider>
          </b-form-group>

          <!-- frais d'envoi -->
          <b-form-group label-for="register-solde">
            <label for=""
              >Frais d'envoi<span class="p-0 text-danger h6"></span
            ></label>
            <validation-provider
              #default="{ errors }"
              name="frais"
              rules="required"
            >
              <b-form-input
                id="register-solde"
                v-model="frais"
                type="number"
                name="register-solde"
                :state="errors.length > 0 ? false : null"
                placeholder="1000000000"
              />
              <!-- <small
                :class="valideSolde ? 'block' : 'none'"
                class="text-danger"
              >
                Veuillez entrer le montant 
              </small> -->
              <small :class="valeurGood ? 'block' : 'none'" class="text-danger">
                Saisie incorrecte. Vous devez renseigner un nombre avec ou sans
                virgule.<br />
                Un POINT( . ) à la place de la virgule( , )
              </small>
            </validation-provider>
          </b-form-group>

          <!-- information debiteur -->
          <hr />
          <h4 class="text-center text-success">
            Information debiteur
            <feather-icon icon="CornerLeftDownIcon" size="18" />
          </h4>

          <!-- Compte debiteur -->
          <b-form-group label="" label-for="register-libelle" class="mt-2">
            <label for=""
              >Selectionner le Compte debiteur
              <span class="p-0 text-danger h6">*</span></label
            >
            <validation-provider
              #default="{ errors }"
              name="compteCredit"
              rules="required"
            >
              <v-select
                v-model="compteCredit"
                placeholder="Selectionnez un compte"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="errors.length > 0 ? false : null"
                label="libelle"
                rules="required"
                @input="validateCompteCredit"
                :options="compteList"
              >
              </v-select>
              <small
                :class="valideMontantCredit ? 'block' : 'none'"
                class="text-danger"
              >
                Veuillez selectionner un compte crediteur
              </small>
            </validation-provider>
          </b-form-group>
        </b-form>

        <template #modal-footer>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            @click="transferer"
            :disabled="loading === true ? true : false"
          >
            <div
              v-if="loading === true"
              class="spinner-border text-primary"
            ></div>
            <span v-else>Valider</span>
          </b-button>
        </template>
      </b-modal>

      <!-- Approvisionner un compte -->

      <b-modal
        id="modal-approvisionner"
        cancel-variant="outline-secondary"
        ok-title="Modifier"
        cancel-title="Annuler"
        centered
        title="Modifier un compte"
        @ok="__approvisionner"
      >
        <b-form class="auth-register-form mt-2">
          <h4 class="text-center text-indigo ">
            Approvisonnement de compte
            <feather-icon icon="CornerLeftUpIcon" size="18" />
          </h4>

             <!-- libelle -->
          <b-form-group label-for="register-libelle">
            <label for=""
              >Source de l'approvisionnement<span class="p-0 text-danger h6">*</span
            ></label>
            <validation-provider
              #default="{ errors }"
              name="libelle"
              rules="required"
            >
              <v-select
                v-model="libelleSource"
                placeholder="Selectionnez une source"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="errors.length > 0 ? false : null"
                label="title"
                rules="required"
                @input="validateSource()"
                :options="source"
              >
              </v-select>
              <small
                :class="valideSource? 'block' : 'none'"
                class="text-danger"
              >
                Vous devez choisir une source pour l'approvisionnement'
              </small>
            </validation-provider>
          </b-form-group>

          <!-- libelle -->
          <b-form-group label-for="register-libelle" v-if="libelleSource.title ==='Autre'">
            <label for=""
              >Libellé de l'approvisionnement<span class="p-0 text-danger h6"></span
            ></label>
            <validation-provider
              #default="{ errors }"
              name="libelle"
              rules="required"
            >
              <b-form-input
                id="libelle"
                v-model="libelleApp"
                type="text"
                name="register-libelle"
                :state="errors.length > 0 ? false : null"
                placeholder="don"
              />
            </validation-provider>
          </b-form-group>

          <!-- montant -->
          <b-form-group label-for="register-solde">
            <label for=""
              >Montant <span class="p-0 text-danger h6">*</span></label
            >
            <validation-provider
              #default="{ errors }"
              name="solde"
              rules="required"
            >
              <b-form-input
                id="register-montant"
                @input="validateMontantApp"
                v-model="montantApp"
                type="number"
                name="register-montant"
                :state="errors.length > 0 ? false : null"
                placeholder="1000000000"
              />
              <small
                :class="valideMontantApp ? 'block' : 'none'"
                class="text-danger"
              >
                Veuillez entrer le montant
              </small>
            </validation-provider>
          </b-form-group>

          <!-- Description-->

          <b-form-group>
            <label for="taxeValue">Description (facultatif):</label>
            <b-form-textarea
              id="textarea"
              v-model="descriptionApp"
              placeholder="Entrer les details de l'approvisionnement"
              rows="5"
              max-rows="6"
            >
            </b-form-textarea>
          </b-form-group>
        </b-form>

        <template #modal-footer>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            @click="__approvisionner"
            :disabled="loading === true ? true : false"
          >
            <div
              v-if="loading === true"
              class="spinner-border text-primary"
            ></div>
            <span v-else>Valider</span>
          </b-button>
        </template>
      </b-modal>

      <b-card no-body class="">
        <!-- Le haut du tableau contenant les barre de recherche et bouton d'ajout de nouvelle taxe -->
        <b-row class="mx-2">
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1"
          >
            <label>Entrées</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />

            <!--  -->
            <b-button v-if="!limited" variant="primary" @click="routeNoAccress">
              <q-button-created title="Nouveau compte " />
            </b-button>
            <b-button
              v-if="limited"
              variant="primary"
              v-b-modal.v-b-modal.modal-login
            >
              <q-button-created title="Nouveau compte" />
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6" class="mt-1">
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="filtreCompte"
                  class="d-inline-block mr-1"
                  placeholder="Rechercher par : numero de compte, libelle, date d'ajout..."
                />
              </b-input-group>
            </div>
          </b-col>
        </b-row>

        <q-loader-table
          :success="state.success"
          :empty="state.empty"
          :warring="state.warring"
        />

        <b-table
          hover
          responsive
          primary-key="id"
          :per-page="perPage"
          :current-page="currentPage"
          :items="comptes"
          :fields="tableColumns"
          :filter="filtreCompte"
          show-empty
          empty-text="Aucun comptes enregistrés"
          class="bg-white text-left"
          v-if="state.success === true"
        >
          <template #cell(numero_compte)="data">
            <b-badge
              variant="light-info "
              class="text-uppercase font-weight-bolder d-inline-flex items-content-center"
            >
              <span :class="'hide-number-compte-' + data.item.id"
                >&#9900; &#9900; &#9900; &#9900;
              </span>
              <span :class="'hidden actived-number-compte-' + data.item.id"
                >{{ data.item.numero_compte }}
              </span>
              <feather-icon
                @click="qViewAccount(data.item.id)"
                :class="
                  'hide-icon-compte-' +
                    data.item.id +
                    ' actived-number-compte-' +
                    data.item.id
                "
                :icon="'EyeOffIcon'"
                class="ml-50"
                size="18"
              />
              <feather-icon
                @click="qViewAccount(data.item.id)"
                :class="'hidden actived-number-compte-' + data.item.id"
                :icon="'EyeIcon'"
                class="ml-50"
                size="18"
              />
            </b-badge>
          </template>

          <template #cell(statut)>
            <b-badge
              variant="light-success"
              class="text-uppercase font-weight-bolder d-inline-flex items-content-center"
              style="font-size: 12px !important"
            >
              Activé
            </b-badge>
          </template>

          <template #cell(libelle)="data">
            <div class="py-50">
              <span variant="info" class="text-uppercase font-weight-bolder">
                {{ data.item.libelle }}
              </span>
            </div>
          </template>
          <template #cell(solde)="data">
            <span
              class="d-flex items-content-center font-weight-bold text-info"
            >
              {{ formatMoney(data.item.solde) }}
            </span>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="flex items-center text-nowrap py-1">
              <div
                v-b-modal.modal-transfert
                @click="transfert(data.item.id)"
                class="flex items-center gap-1 text-white bg-orange-500 text-xs font-bold rounded-md px-[6px] py-[4px] mr-1  "
                :id="`invoice-row-${data.item.id}-send-icon`"
              >
                <feather-icon
                  icon="SendIcon"
                  size="12"
                  class=" cursor-pointer text-white"
                />
                <span>Transfert</span>
              </div>

              <b-tooltip
                title="Transferer de l'argent"
                :target="`invoice-row-${data.item.id}-send-icon`"
              />

              <!-- <div
                v-b-modal.modal-approvisionner
                @click="approvisionner(data.item)"
                class="flex items-center gap-1 text-white bg-indigo-600 text-xs font-bold rounded-md px-[6px] py-[4px]  "
                :id="`invoice-row-${data.item.id}-dollar-sign-icon`"
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="12"
                  class=" cursor-pointer text-white"
                />
                <span>Approvisionner</span>
              </div> -->

              <b-tooltip
                title="Approvisionner ce compte"
                :target="`invoice-row-${data.item.id}-dollar-sign-icon`"
              />

              <feather-icon
                :disabled="data.item.libelle === 'caisse' ? true : false"
                v-b-modal.modal-update
                @click="update(data.item.id)"
                :id="`invoice-row-${data.item.id}-edit-icon`"
                icon="Edit3Icon"
                size="16"
                class="mx-1"
                :class="
                  data.item.libelle === 'caisse'
                    ? 'opacity-1'
                    : 'cursor-pointer'
                "
              />

              <b-tooltip
                title="editer un compte"
                :target="`invoice-row-${data.item.id}-edit-icon`"
              />

              <feather-icon
                v-if="data.item.libelle === 'caisse' ? false : true"
                @click="confirmText(data.item.id)"
                :id="`invoice-row-${data.item.id}-trash-icon`"
                icon="TrashIcon"
                class=" cursor-pointer"
                size="16"
              />

              <feather-icon
                v-if="data.item.libelle !== 'caisse' ? false : true"
                icon="TrashIcon"
                class=" cursor-pointer"
                :id="`invoice-row-${data.item.id}-trash-icon`"
                size="16"
              />

              <b-tooltip
                title="Supprimer un compte"
                :target="`invoice-row-${data.item.id}-trash-icon`"
              />

              <!-- Dropdown -->
              <b-dropdown
                variant="link"
                toggle-class="p-0"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body ml-1"
                  />
                </template>
                <!-- <b-dropdown-item @click="editFac(data.item.id)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Modifier</span>

            </b-dropdown-item> -->
                <!-- <b-dropdown-item> -->
                <!-- <feather-icon icon="SendIcon"
                    v-b-modal.modal-update
              @click="update(data.item.id)" /> -->
                <!-- <span class="align-middle ml-50">Transferer</span> -->
                <!-- </b-dropdown-item> -->

  <b-dropdown-item>
                 

                   <div
                v-b-modal.modal-approvisionner
                @click="approvisionner(data.item)"
                class="flex w-full items-center gap-1"
              
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="12"
                  class=" cursor-pointer"
                />
                <span>Recharger</span>
              </div>
                </b-dropdown-item>

                <b-dropdown-item>
                  <div class="flex w-full items-center">
                    <feather-icon icon="LockIcon" />
                    <span class="align-middle ml-50">Desactiver</span>
                  </div>

             
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>

          <template #cell(created_at)="data">
            {{ format_date(data.item.created_at) }}
          </template>
        </b-table>

        <!-- Les boutons de pagination -->
        <div class="mx-2 mb-2 pb-1">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"></span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="compteTotal"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
      {{ __NEXT }}
    </div>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BFormInput,
  BFormGroup,
  BButton,
  VBModal,
  BForm,
  BLink,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import URL from "@/views/pages/request";
import axios from "axios";
import moment from "moment";
import { VueTelInput } from "vue-tel-input";
import { computed, onBeforeMount, ref } from "@vue/composition-api";
import {
  toast_destory,
  toast_error,
  toast_loading,
  toast_sucess,
  toast_update,
} from "@/utils/qToast";
import QLoaderTable from "@/components/__partials/loaders/qLoaderTable.vue";
import qDeleteAtUtils from "@/utils/qDeleteAtUtils";
import qDeviseUtils from "@/utils/qDeviseUtils";
import { qDecrypted } from "@/services/qeCrypt";
import QPermissions from "../../access/qPermissions.vue";
import QButtonCreated from "@/components/qButtonCreated.vue";
import qeEntrepriseInfo from "@/services/qeEntrepriseInfo";
export default {
  components: {
    moment,
    VueTelInput,
    vSelect,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    BFormGroup,
    VBModal,
    BForm,
    BImg,
    BLink,
    BForm,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    QLoaderTable,
    QPermissions,
    QButtonCreated,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // validation
      config: {
        headers: {
          Accept: "application/json",
        },
      },
      countAccout: 0,
      qViewAccount__on: 0,
      qViewAccount__bool: false,
      numeroCompte: "",
      valideNumeroCompte: false,

      libelle: "",
      valideLibelle: false,

      solde: "",
      valideSolde: false,

      description: "",
      compte_exist: "",

      //validation Update
      editNumeroCompte: "",
      valideEditNumeroCompte: false,
      editLibelle: "",
      valideEditLibelle: false,
      editSolde: "",
      valideEditSolde: false,
      editDescription: "",
      compteUp: "",
      edit_index: "",

      valeurGood: false,
      valeurEditGood: false,
      returnData: "",
      required,
      valideExiste: false,
      id_update: "",
      updateIndex: "",

      //variable compte approvisionnement

      compteApp: "",
      soldeCp: "",

      libelleApp: "",
      libelleSource: "",
      montantApp: "",
      descriptionApp: "",
      valideMontantApp: false,
      valideSource:false,

      source: [{ title: "Don" }, { title: "Emprunt" }, { title: "Autre" }],
      //variable tranfert
      valideMontantDebit: false,
      montantDebit: "",
      compteDebit: "",
      valideCompteDebit: false,
      compteCredit: "",
      frais: "",
      compteCredit: "",
      valideMontantCredit: false,
      loading: false,

      perPage: 30,
      currentPage: 1,
      compteTotal: 0,
      tableColumns: [
        { key: "numero_compte", label: "N° Compte" },
        { key: "libelle", label: "Libellé", sortable: true },
        { key: "solde", label: "Solde" },
        { key: "statut", label: "Statut" },
        { key: "created_at", label: "Date d'ajout" },
        { key: "actions" },
      ],
      state: {
        loading: false,
        success: false,
        empty: false,
        warring: false,
      },
      filtreCompte: "",
      perPageOptions: [30, 50, 100, 150, 250, 500],
      loadingDelay: 0,
      GIVE: false,
    };
  },
  computed: {
    __NEXT: function() {
      if (this.GIVE === true) {
        this.comptes.length === 0
          ? ((this.state.empty = true), (this.state.success = false))
          : ((this.state.success = true), (this.state.empty = false));
      }
      return;
    },
  },
  async mounted() {
    document.title = "Comptes - Ediqia";
    const config = {
      headers: {
        Accept: "application/json",
      },
    };
    try {
      await axios
        .get(URL.COMPTE_LIST)
        .then((response) => {
          this.returnData = response;
          this.compteTotal = response.data[0].length;
          for (let index = 0; index < this.returnData.data[0].length; index++) {
            this.comptes.push(this.returnData.data[0][index]);
          }
          this.comptes = qDeleteAtUtils.delete_update_at(this.comptes);
          this.GIVE = true;
          this.comptes.reverse();
        })

        .catch((error) => {
          console.log(error);
        });

      await axios
        .get(URL.COMPTE_LIST, config)
        .then((response) => {
          this.compteList = response.data[0];
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      toast_error(
        this,
        "success",
        "top-right",
        "Oups, une erreur est survenue !"
      );
    }
  },
  methods: {
    qViewAccount(id) {
      this.countAccout += 1;
      this.qViewAccount__on = id;

      let icon = document.querySelector(".hide-icon-compte-" + id);
      let hideAccount = document.querySelectorAll(".hide-number-compte-" + id);
      let activedAccount = document.querySelectorAll(
        ".actived-number-compte-" + id
      );

      let result = false;
      hideAccount.forEach((el) => {
        result = el.classList.toggle("hidden");
      });

      if (result) {
        activedAccount.forEach((el) => {
          el.classList.remove("hidden");
        });
        icon.classList.add("hidden");
      } else {
        activedAccount.forEach((el) => {
          el.classList.add("hidden");
        });
        icon.classList.remove("hidden");
      }
    },

    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },

    formatMoney(amount) {
      const dataToDecrypt = localStorage.getItem("entreprise_info");
      return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
    },

    //validationStore
    validateNumeroCompte() {
      if (!this.numeroCompte) {
        this.valideNumeroCompte = true;
      } else {
        this.valideNumeroCompte = false;
      }
    },

    validateLibelle() {
      if (!this.libelle) {
        this.valideLibelle = true;
      } else {
        this.valideLibelle = false;
      }
    },

    validateSolde() {
      if (!this.solde) {
        this.valideSolde = true;
      } else {
        this.valideSolde = false;
      }

      if (!this.solde) {
        this.valideSolde = true;
      } else {
        this.valideSolde = false;
      }
      if (this.solde && this.isFloat(this.solde) === false) {
        if (this.isDigit(this.solde) === false) {
          this.valeurGood = true;
        } else {
          this.valeurGood = false;
        }
      } else {
        this.valeurGood = false;
      }
    },

    //validationUpdate
    validateEditNumeroCompte() {
      if (!this.editNumeroCompte) {
        this.valideEditNumeroCompte = true;
      } else {
        this.valideEditNumeroCompte = false;
      }
    },

    validateEditLibelle() {
      if (!this.editLibelle) {
        this.valideEditLibelle = true;
      } else {
        this.valideEditLibelle = false;
      }
    },

    validateEditSolde() {
      if (!this.editSolde) {
        this.valideEditSolde = true;
      } else {
        this.valideEditSolde = false;
      }

      if (!this.editSolde) {
        this.valideEditSolde = true;
      } else {
        this.valideEditSolde = false;
      }
      if (this.editSolde && this.isFloat(this.editSolde) === false) {
        if (this.isDigit(this.editSolde) === false) {
          this.valeurEditGood = true;
        } else {
          this.valeurEditGood = false;
        }
      } else {
        this.valeurEditGood = false;
      }
    },

    validateMontantDebit() {
      if (!this.montantDebit) {
        this.valideMontantDebit = true;
      } else {
        this.valideMontantDebit = false;
      }
      if (this.montantDebit > this.solde) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Le solde de votre compte est insuffisant",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        this.montantDebit = "";
      }
    },

    validateCompteDebit() {
      if (!this.compteDebit) {
        this.valideCompteDebit = true;
      } else {
        this.valideCompteDebit = false;
      }

      if (this.compteCredit.id === this.compteDebit.id) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "vous ne pouvez pas transferer de l'argent vers le même compte",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },

    validateCompteCredit() {
      if (!this.compteCredit) {
        this.valideMontantCredit = true;
      } else {
        this.valideMontantCredit = false;
      }

      if (this.compteCredit.id === this.compteDebit.id) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "vous ne pouvez pas transferer de l'argent vers le même compte",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        this.compteCredit = "";
      }
    },

    isDigit(str) {
      return /^\d+$/.test(str);
    },
    isFloat(str) {
      return /^[-+]?[0-9]+\.[0-9]+$/.test(str);
    },

    validateValeur() {
      if (!this.solde) {
        this.valideSolde = true;
      } else {
        this.valideSolde = false;
      }
      if (this.solde && this.isFloat(this.solde) === false) {
        if (this.isDigit(this.solde) === false) {
          this.valeurEditGood = true;
        } else {
          this.valeurEditGood = false;
        }
      } else {
        this.valeurEditGood = false;
      }
    },

    // confirm texrt
    confirmText(id) {
      this.$swal({
        title: `Êtes vous sûr !?`,
        text: "Ce compte sera supprimer définitivement !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteCompte(id);
        }
      });
    },
    // top end
    topEnd() {
      this.$swal({
        position: "top-end",
        icon: "success",
        title: "Client enregistrée avec succès",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    async deleteCompte(identifiant) {
      try {
        const id = {
          id: identifiant,
        };
        await axios
          .post(URL.COMPTE_DESTROY, id)
          .then((response) => {
            if (response.data) {
              toast_destory(this, "success", "top-right", "Compte");
              this.comptes = this.comptes.filter(
                (compte) => compte.id !== identifiant
              );
              this.comptes.length === 0
                ? (this.state.empty = true)
                : (this.state.success = true);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    },
    transfert(indice) {
      const element = this.comptes.filter((item) => {
        return item.id === indice;
      });
      this.compteUp = element[0];
      this.compteDebit = this.compteUp;
      this.solde = this.compteUp.solde;
    },

    approvisionner(item) {
      this.compteApp = item.id;
      this.soldeCp = item.solde;
      console.log(this.compteApp, this.soldeCp);
    },

    update(indice) {
      const element = this.comptes.filter((item) => {
        return item.id === indice;
      });
      this.compteUp = element[0];
      this.editNumeroCompte = this.compteUp.numero_compte;
      this.editLibelle = this.compteUp.libelle;
      this.editSolde = this.compteUp.solde;
      this.editDescription = this.compteUp.description;
      this.edit_index = indice;
      this.id_update = this.compteUp.id;
    },
    async updateCompte(bvModalEvt) {
      const compte = this.compteUp;
      this.validateEditNumeroCompte();
      this.validateEditLibelle();
      this.validateEditSolde();
      if (
        this.valideEditNumeroCompte ||
        this.valideEditLibelle ||
        this.valideEditSolde
      ) {
        bvModalEvt.preventDefault();
      } else {
        if (
          compte.numero_compte != this.editNumeroCompte ||
          compte.libelle != this.editLibelle ||
          compte.solde != this.editSolde ||
          compte.description != this.editDescription
        ) {
          this.state.loading = true;
          try {
            const data = {
              id: this.id_update,
              numero_compte: this.editNumeroCompte,
              libelle: this.editLibelle,
              solde: this.editSolde,
              description: this.editDescription,
            };

            axios
              .post(URL.COMPTE_UPDATE, data, this.config)
              .then((response) => {
                if (response.data) {
                  toast_update(
                    this,
                    "success",
                    "top-right",
                    "Compte modifer avec succés !"
                  );
                  this.state.loading = false;
                  this.$bvModal.hide("modal-update");

                  this.comptes.forEach((compte) => {
                    if (compte.id === data.id) {
                      compte.numero_compte = data.numero_compte;
                      compte.libelle = data.libelle;
                      compte.solde = data.solde;
                      compte.description = data.description;
                    }
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } catch (error) {
            console.log(error.type);
            toast_error(
              this,
              "success",
              "top-right",
              "Oups, une erreur est survenue !"
            );
          }
        }
      }
    },

    async transferer() {
      try {
        this.validateCompteDebit();
        this.validateMontantDebit();
        this.validateCompteCredit();
        if (!this.compteDebit || !this.montantDebit || !this.compteCredit) {
          this.$swal({
            title: "Veuillez renseigner les champs obligatoires",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__shakeX",
            },
            buttonsStyling: false,
          });
        } else {
          let montantTotal = Number(this.montantDebit) + Number(this.frais);
          const data = {
            compteDebit: this.compteDebit.id,
            compteCredit: this.compteCredit.id,
            montant: montantTotal,
          };
          this.loading = true;
          await axios
            .post(URL.COMPTE_TRANSFERT, data, this.config)
            .then(({ data }) => {
              this.loading = false;
              this.$bvModal.hide("modal-transfert");
              toast_sucess(
                this,
                "success",
                "top-right",
                "Compte créer avec succés !"
              );

              const _compteDebit = this.comptes.filter((debite) => {
                return debite.id === this.compteDebit.id;
              });

              _compteDebit[0].solde = _compteDebit[0].solde - montantTotal;

              const _compteCredit = this.comptes.filter((debite) => {
                return debite.id === this.compteCredit.id;
              });

              _compteCredit[0].solde = _compteCredit[0].solde + montantTotal;

              this.montantDebit = "";
              this.compteDebit = "";
              this.compteCredit = "";
              this.frais = "";

              this.$bvModal.hide("modal-login");
              //
            });
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },

    validateMontantApp() {
      if (!this.montantApp) {
        this.valideMontantApp = true;
      } else {
        this.valideMontantApp = false;
      }
    },

     validateSource() {
      if (!this.libelleSource) {
        this.valideSource = true;
      } else {
        this.valideSource = false;
      }
      if (this.libelleSource.title ==='Emprunt') {
        this.$router.push('/newEmprunt');
      }
      console.log(this.libelleSource.title)
    },

    async __approvisionner() {
      try {
        this.validateMontantApp();
        this.validateSource();

        if (this.montantApp && this.libelleSource) {
          const __montant = parseInt(this.soldeCp) + parseInt(this.montantApp);
          console.log(__montant);

          const data = {
            montant: this.montantApp,
            compte: this.compteApp,
            upsolde: __montant,
            source: this.libelleSource.title,
            description:this.descriptionApp,
            libelle:this.libelleApp
          };
          console.log(data);
   this.loading = true;
          await axios.post(URL.COMPTE_APPROVISIONNEMENT, data,).then((response) => {
              this.loading = false;
               toast_sucess(
                this,
                "success",
                "top-right",
                "Operation éffectué avec succés !"
              );
              this.montantApp="";
this.compteApp="";
this.libelleSource="";
this.descriptionApp="";
this.libelleApp="";
              this.$bvModal.hide("modal-approvisionner");
              if (response.data) {
                 axios
        .get(URL.COMPTE_LIST)
        .then((response) => {
          this.returnData = response;
          this.compteTotal = response.data[0].length;
          this.comptes = response.data[0]
        })

              }
          });
        }
      } catch (error) {
   this.loading = false;
      }
    },

    // if_exist() {
    //   for (let index = 0; index < this.comptes.length; index++) {
    //     if (this.numeroCompte == this.comptes[index].numero_compte) {
    //       this.valideExiste = true;
    //       this.compte_exist =
    //         'Ce compte existe déjà! Veuillez vérifier le numero';
    //     } else {
    //       this.valideExiste = false;
    //     }
    //   }
    // },

    async handleOk(bvModalEvt) {
      this.validateNumeroCompte(), this.validateLibelle(), this.validateSolde();
      try {
        this.validateNumeroCompte();
        this.validateLibelle();
        this.validateSolde();
        // this.if_exist();

        if (
          this.valideNumeroCompte ||
          this.valideLibelle ||
          this.valideSolde ||
          this.valideExiste ||
          this.valeurGood
        ) {
          bvModalEvt.preventDefault();
          this.handleSubmit();
        } else {
          const newCompte = {
            numero_compte: this.numeroCompte,
            libelle: this.libelle,
            solde: this.solde,
            description: this.description,
          };

          this.state.loading = true;

          await axios
            .post(URL.COMPTE_CREATE, newCompte, this.config)
            .then(({ data }) => {
              toast_sucess(
                this,
                "success",
                "top-right",
                "Compte créer avec succés !"
              );
              this.comptes.unshift(data.comptes[0]);
              this.state.loading = false;
              this.$bvModal.hide("modal-login");

              //
              this.numeroCompte = "";
              this.libelle = "";
              this.solde = "";
              this.description = "";
            });
        }
      } catch (error) {
        this.state.loading = false;
        toast_error(
          this,
          "success",
          "top-right",
          "Oups, une erreur est survenue !"
        );
        console.log(error);
      }
    },
  },
  setup(props, { root }) {
    const comptes = ref([]);
    const compteList = ref([]);
    const limited = ref(false);
    onBeforeMount(async () => {
      console.log("compteList", comptes.value);
      const { isLimited } = await qeEntrepriseInfo.limitedSubcrible(
        comptes.value,
        15
      );
      limited.value = isLimited;
    });

    const routeNoAccress = () => {
      qeEntrepriseInfo.qUpgardeToAccess(root);
    };

    const permStatus = computed(() => {
      return root.$store.state.qPermissions.statuts;
    });
    return {
      limited,
      permStatus,
      routeNoAccress,
      compteList,
      comptes,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.table-base {
  margin: 30px auto 0;
}

.tableau {
  box-shadow: 0px 6px 46px -21px rgba(0, 0, 0, 0.75);
}

.table-card {
  width: 100%;
  margin: auto;
  border-radius: 13px;
}

.table-card thead tr {
  border-radius: 13px;
  background-color: rgb(68, 68, 68) !important;
}

.table-card thead tr th {
  background-color: rgb(68, 68, 68) !important;
  color: white;
}

.add-btn {
  position: absolute;
  right: 0;
  top: -50px;
  background-color: #450077;
}

.none {
  display: none;
}
.block {
  display: inline-block;
}
.red:after {
  content: " *";
  color: red;
}

// #modal-transfert___BV_modal_content_ {
//   // height: 105vh !important;
// }
</style>
